<template>
  <v-card class="mt-3">
    <v-card-title>
      <v-col class="col-4">
        Translations:
      </v-col>
      <v-col>
        <v-select
          v-model="selectedLanguage"
          label="Select Language"
          :items="setLanguages"
          :value="setLanguages.text"
          @change="changeSelectedLanguage()"
        />
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-row v-if="selectedLanguage">
        <v-col cols="6">
          <v-card class="fill-height">
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              />
            </v-overlay>
            <v-card-title>
              Selected Manual
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Manual Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ manual.manual_s3_path.split('/manuals/')[1] }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Default Label
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ manual.label_value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="fill-height">
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              />
            </v-overlay>
            <v-card-title>
              Manual Label Translate {{ selectedLanguage }}
            </v-card-title>
            <v-card-text class="mt-10">
              <v-text-field
                v-model="newValue"
                label="Manual label"
                required
                :counter="255"
                @keyup="addIsDisabled = false"
              />
            </v-card-text>
            <v-card-actions class="mt-8">
              <v-spacer />
              <small
                v-if="showMessage"
                class="success--text"
              >
                <b>Translation added.</b> Click Save to proceed, Cancel to discard.
              </small>
              <v-btn
                dark
                color="color_green"
                :disabled="addIsDisabled"
                @click="add()"
              >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col class="text-right">
          <v-btn
            dark
            color="warning"
            @click="close()"
          >
            Close
          </v-btn>
        </v-col>
        <v-col class="d-flex text-left">
          <v-btn
            :dark="!saveIsDisabled"
            color="color_green"
            :disabled="saveIsDisabled"
            @click="save()"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { LANGUAGES } from '@/constants.js';

export default {
  props: {
    manual: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      newValue: '',
      selectedLanguage: '',
      overlay: false,
      showMessage: false,
      addIsDisabled: true,
      translations: {},
      languages: []
    };
  },
  computed: {
    ...mapState(['customer']),
    setLanguages() {
      return LANGUAGES;
    },
    saveIsDisabled() {
      return this.languages.length === 0;
    }
  },
  methods: {
    ...mapActions(['storeTranslationManual', 'createEditTranslationAction']),
    async changeSelectedLanguage() {
      this.overlay = true;
      const data = {
        label_code: this.manual.label_code,
        lang: this.selectedLanguage.split('-')[0]
      };
      await this.storeTranslationManual(data);
      this.newValue = this.translations[`${this.selectedLanguage.split('-')[0]}`] || this.customer.storeTranslationManual;
      this.showMessage = false;
      this.addIsDisabled = true;
      this.overlay = false;
    },
    add() {
      this.translations[`${this.selectedLanguage.split('-')[0]}`] = this.newValue;
      this.languages.push(this.selectedLanguage.split('-')[0]);
      this.showMessage = true;
      this.addIsDisabled = true;
    },
    async save() {
      this.overlay = true;
      const promises = [];
      this.languages.forEach(item => {
        const data = {
          label_code: this.manual.label_code,
          label_value: this.translations[item],
          lang: item
        };
        promises.push(this.createEditTranslationAction(data));
      });
      await Promise.all(promises);
      this.translations = {};
      this.languages = [];
      this.$emit('close-translation-tab');
      this.overlay = false;
    },
    close() {
      this.translations = {};
      this.languages = [];
      this.$emit('close-translation-tab');
    }
  }
};
</script>
